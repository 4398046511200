import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "appfull modulewrap"
};
const _hoisted_2 = {
  key: 0,
  class: "mainwrap"
};
const _hoisted_3 = {
  class: "mainbody"
};
const _hoisted_4 = {
  class: "mawrap"
};
const _hoisted_5 = {
  class: "matoolbar2"
};
const _hoisted_6 = {
  class: "dwmywrap"
};
const _hoisted_7 = {
  class: "mamain mycharts"
};
import CmanagerSide from '@/components/CmanagerSide.vue';
export default {
  __name: 'charts',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const FFdata = reactive({
      isLoad: false,
      resData: {},
      chartsData: [],
      kwSearch: '',
      itemCur: {},
      isShowMore: false,
      filterDate: '',
      dateType: 3,
      shortcuts: [{
        text: '最近一周',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        }
      }, {
        text: '最近一月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        }
      }, {
        text: '最近三月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        }
      }, {
        text: '最近一年',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
          return [start, end];
        }
      }, {
        text: '最近三年',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
          return [start, end];
        }
      }]
    });
    const dwmyChange = v => {
      FFdata.dateType = v;
      getData();
    };
    const getFukterData = () => {
      //数据过滤方法
      let filterdata = {};
      if (FFdata.filterDate) {
        filterdata.datebegin = FFdata.filterDate[0] || "";
        filterdata.dateend = FFdata.filterDate[1] || "";
      }
      filterdata.datetype = FFdata.dateType;
      return proxy.$common.basic.isObjExist(filterdata) ? filterdata : '';
    };
    const getData = () => {
      let filterdatalast = getFukterData();
      let jsondata = {
        'postdata': {
          'act': 'ai_macharts',
          'data': filterdatalast
        }
      };
      proxy.$common.post(jsondata, res => {
        FFdata.isLoad = true;
        FFdata.chartsData = res.infodata;
      });
    };
    onMounted(() => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
      FFdata.filterDate = [proxy.$common.basic.dateFormat(start), proxy.$common.basic.dateFormat(end)];
      getData();
    });
    return (_ctx, _cache) => {
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_ffcharts = _resolveComponent("ffcharts");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(FFdata).isLoad ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(CmanagerSide, {
        navIndex: 4
      }), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", null, [_createVNode(_component_el_date_picker, {
        size: "small",
        modelValue: _unref(FFdata).filterDate,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(FFdata).filterDate = $event),
        type: "daterange",
        align: "right",
        "unlink-panels": "",
        "range-separator": "/",
        "start-placeholder": "开始日期",
        "end-placeholder": "结束日期",
        "value-format": "YYYY-MM-DD",
        shortcuts: _unref(FFdata).shortcuts,
        onChange: getData
      }, null, 8, ["modelValue", "shortcuts"])]), _createElementVNode("div", _hoisted_6, [_createElementVNode("a", {
        href: "#",
        class: _normalizeClass({
          cs: _unref(FFdata).dateType == 1
        }),
        onClick: _cache[1] || (_cache[1] = $event => dwmyChange(1))
      }, "日", 2), _createElementVNode("a", {
        href: "#",
        class: _normalizeClass({
          cs: _unref(FFdata).dateType == 2
        }),
        onClick: _cache[2] || (_cache[2] = $event => dwmyChange(2))
      }, "周", 2), _createElementVNode("a", {
        href: "#",
        class: _normalizeClass({
          cs: _unref(FFdata).dateType == 3
        }),
        onClick: _cache[3] || (_cache[3] = $event => dwmyChange(3))
      }, "月", 2), _createElementVNode("a", {
        href: "#",
        class: _normalizeClass({
          cs: _unref(FFdata).dateType == 4
        }),
        onClick: _cache[4] || (_cache[4] = $event => dwmyChange(4))
      }, "年", 2)])]), _createElementVNode("div", _hoisted_7, [_unref(FFdata).isLoad ? (_openBlock(), _createBlock(_component_ffcharts, {
        key: 0,
        chartsInfo: _unref(FFdata).chartsData
      }, null, 8, ["chartsInfo"])) : _createCommentVNode("", true)])])])])) : _createCommentVNode("", true)]);
    };
  }
};